// external dependencies
import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';
import CancelIcon from '@material-ui/icons/Cancel';

// gatsby dependencies
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';

// internal dependencies
import SEO from '../../components/SEO';
import Hero from '../../components/shared/Hero';
import Section from '../../components/shared/Section';
import Grid from '@material-ui/core/Grid';
import Cta from '../../components/shared/Cta';

const StyledImg = styled(GatsbyImage)`
  min-width: 300px;
  height: 300px;
  box-shadow: ${(props) => props.theme.effects.boxShadow};
  cursor: pointer;
  transition: 0.3s;
  border-radius: ${(props) => props.theme.elements.borderRadius};

  &:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    transition: 0.3s;
  }

  @media (max-width: 600px) {
    width: 100%;
    min-width: 450px;
  }

  @media (max-width: 500px) {
    width: 100%;
    min-width: unset;
    min-width: 300px;
  }
`;

const StyledModal = styled.div`
  background: white;
  overflow: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  box-shadow: ${(props) => props.theme.effects.boxShadow};
  border-radius: ${(props) => props.theme.elements.borderRadius};

  &:focus {
    outline: none;
  }

  @media (max-width: 650px) {
    width: 80vw;
  }
`;

const StyledModalTitleWrapper = styled.div`
  position: absolute;
  bottom: 15px;
  right: 15px;
  background: rgba(255, 255, 255, 0.7);
  padding: 10px 15px;
  border-radius: ${(props) => props.theme.elements.borderRadius};

  h5 {
    color: ${(props) => props.theme.colors.blue};
  }
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  width: 75vw;
  height: 75vh;

  @media (max-width: 600px) {
    width: 100%;
    height: 500px;
  }
`;

// component definition
const GalleryPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalImage, setModalImage] = useState();
  const [modalTitle, setModalTitle] = useState();

  const handleOpen = (image, title) => {
    setModalImage(image);
    setModalTitle(title);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const data = useStaticQuery(graphql`
    query {
      page: contentfulGalleryPage {
        page
        metaDescription
        ctaSection {
          email
          headline
          phoneNumber
          supertext
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            title            
          }
        }
        hero {
          headline
          buttonLink
          buttonText
          supertext
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            title
          }
        }
        gallery {
          media {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            title
          }
        }
      }
    }
  `);

  const renderModalImage = (
    <StyledBackgroundImage image={modalImage}>
      <StyledModalTitleWrapper>
        <h5>{modalTitle}</h5>
      </StyledModalTitleWrapper>
    </StyledBackgroundImage>
  );

  const gallery = data.page.gallery.media.map((item, i) => {
    return (
      <>
        <Grid
          key={i}
          item
          onClick={() => handleOpen(item.gatsbyImageData, item.title)}
          md={4}
          sm={6}
          xs={12}
        >
          <StyledImg image={item.gatsbyImageData} alt={item.title}/>
        </Grid>
      </>
    );
  });

  return (
    <>
      <SEO title={data.page.page} description={data.page.metaDescription} />
      <Hero
        short
        bkgImage={data.page.hero.image}
        headline={data.page.hero.headline}
        buttonLink={data.page.hero.buttonLink}
        buttonText={data.page.hero.buttonText}
        supertext={data.page.hero.supertext}
      />
      <Section>
        <Grid
          container
          wrap='wrap'
          justifyContent='center'
          align='flex-start'
          spacing={3}
        >
          {gallery}
        </Grid>
      </Section>
      <Cta
        ctaSupertext={data.page.ctaSection.supertext}
        ctaHeadline={data.page.ctaSection.headline}
        phoneNumber={data.page.ctaSection.phoneNumber}
        email={data.page.ctaSection.email}
        ctaImage={data.page.ctaSection.image}
      />
      {isOpen && (
        <Modal
          open={isOpen}
          onClose={handleClose}
          // aria-labelledby='simple-modal-title'
          // aria-describedby='simple-modal-description'
        >
          <StyledModal>
            <CancelIcon
              onClick={handleClose}
              style={{
                position: 'absolute',
                top: 10,
                right: 10,
                cursor: 'pointer',
                zIndex: 1,
              }}
              htmlColor='#ffffff'
              fontSize='large'
            />
            {renderModalImage}
          </StyledModal>
        </Modal>
      )}
    </>
  );
};

export default GalleryPage;
